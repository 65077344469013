import * as bootstrap from "bootstrap";
import * as aos from "aos";
import { eventListeners } from "@popperjs/core";
import * as orderTaxi from "./orderTaxi";

// For development
if (module.hot) {
  module.hot.accept();
}

aos.init({
  duration: 1200,
});

//From here for production bootstrap

window.onload = (event) => {
  //this year
  var d = new Date();
  var year = d.getFullYear();

  document.querySelector(".currentYear").innerHTML = year;
};
