//Start logic form order
var pickUpTime = "";
var pickUpAdress = "";
var destinationAdress = "";
var personCount = 0;

document.getElementById("order-taxi").addEventListener("click", sendWhatsapp);

document.getElementById("DateTime").addEventListener("change", (event) => {
  const d = new Date(event.target.value);
  pickUpTime = `${d.toDateString()} ${d.toTimeString()}`;
});

document.getElementById("PickUpAdress").addEventListener("change", (event) => {
  pickUpAdress = event.target.value;
});

document
  .getElementById("DestinationAdress")
  .addEventListener("change", (event) => {
    destinationAdress = event.target.value;
  });

document.getElementById("PersonCount").addEventListener("change", (event) => {
  personCount = event.target.value;
});

function sendWhatsapp() {
  //send Whatsapp Taxi order
  var message = `Ophaaltijd / Pick Up Time: ${pickUpTime}\nVertrek Punt / Departure Point: ${pickUpAdress}\nBestemming / Destination: ${destinationAdress}\nAantal Personen / Number Of Persons: ${personCount}`;

  var whatsapp =
    "https://api.whatsapp.com/send/?phone=31641320900&text=" +
    encodeURIComponent(message);

  window.location.href = whatsapp;
}
//end logic form order
